import React, { useState, useCallback } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useTenant } from "@nestoca/multi-tenant";
import { Button, Flex, useBreakpointValue, OverflowMenu } from "@nestoca/ui";
import { useAnalytics } from "@shared/analytics";
import { useGetAccount, useMutateAccount } from "@shared/api/hooks/account";
import { UserSettingsModal } from "@shared/ui/components/modals";
import { capitalizeFirstLetters } from "@shared/utils";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { BsBuilding } from "react-icons/bs";
import { BsGear } from "react-icons/bs";
import { IoIosLogOut } from "react-icons/io";
import { IoLanguage } from "react-icons/io5";
import { MdOutlinePrivacyTip } from "react-icons/md";
import { toast } from "react-toastify";

import { NavbarLogo } from "./navbar-logo";
import styles from "./navbar.module.scss";

const iconStyle = {
  minWidth: 16,
  marginRight: 10,
  minHeight: 16,
};

export const AuthenticatedHeader = () => {
  const { track } = useAnalytics();
  const tenant = useTenant();
  const router = useRouter();
  const { locale, asPath, defaultLocale, replace } = router;
  const portfolioHref = locale === defaultLocale ? "/" : `/${locale}`;
  const { logout } = useAuth0();
  const { t } = useTranslation();
  const { data: account, isLoading, isError, error } = useGetAccount();
  const accountMutation = useMutateAccount();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isMobile = useBreakpointValue({ default: true, md: false });

  const onLanguageClick = useCallback(() => {
    const selectedLanguage = locale === "en" ? "fr" : "en";

    if (!account) {
      return toast(t("failedToSave", { ns: "error" }), { type: "error" });
    }

    accountMutation.mutateAsync(
      { ...account, preferredLanguage: selectedLanguage },
      {
        onSuccess: () => {
          replace(asPath, asPath, {
            locale: selectedLanguage,
          });
        },
        onError: () => {
          toast(t("dataUpdatedFailure", { ns: "applications" }), {
            type: "error",
          });
        },
      }
    );
  }, [account, asPath, replace, t, accountMutation, locale]);

  if (isLoading || !tenant || isMobile) {
    return null;
  }

  // When query is failing, we want to throw the error to the error boundary
  // it happen only when the hook is in the layout function on th `_app` `getLayout`
  if (isError) {
    throw error;
  }

  const handleLogout = () => {
    track({ event: "logout" });
    logout({
      logoutParams: {
        /**
         * TODO: This is temporary for a hotfix to prod. We need to figure out a better way to customize this.
         * Maybe an env variable?
         */
        returnTo: `${window.location.origin}/`,
        federated: true,
      },
    });
  };

  const applicantInitials = !isError
    ? capitalizeFirstLetters(t, account?.firstName as string, account?.lastName)
    : "";

  const option = (option: ApplicantMenuItemsType) => {
    return option.link ? (
      <Link
        key={option.id}
        href={option.link}
        passHref
        target="_blank"
        className={styles.external_link}
        // needs to have legacy behaviour to drill down the `<a>` tag on the button
        // and not end up with duplicate nested anchor `<a><a>...</a></a>`
        legacyBehavior
      >
        <Button
          as="a"
          size="small"
          variant="alternative"
          className={styles.dropdown_menu_item}
          data-dd-action-name={option.label}
        >
          {option.icon}
          {option.label}
        </Button>
      </Link>
    ) : (
      <Button
        key={option.id}
        size="small"
        variant="alternative"
        onClick={option.onClick}
        className={styles.dropdown_menu_item}
        data-dd-action-name={option.label}
      >
        {option.icon}
        {option.label}
      </Button>
    );
  };

  const MenuButton = () => {
    return (
      <Button
        variant="alternative"
        className={styles.applicant_menu}
        data-dd-action-name="avatar menu"
      >
        {applicantInitials}
      </Button>
    );
  };

  const applicantMenuItems: ApplicantMenuItemsType[] = [
    {
      id: "userSettings",
      label: t("userSettings"),
      onClick: () => {
        setIsModalVisible(true),
          track({
            event: "view_settings",
          });
      },
      icon: (
        <BsGear
          key="icon-gear"
          size={16}
          color="var(--color-brand)"
          style={iconStyle}
        />
      ),
    },
    {
      id: "language",
      label: t("language", { locale: locale === "en" ? "FR" : "EN" }),
      onClick: onLanguageClick,
      icon: (
        <IoLanguage
          key="icon-language"
          size={16}
          color="var(--color-brand)"
          style={iconStyle}
        />
      ),
    },
    {
      id: "privacyPolicy",
      label: t("privacyPolicy"),
      link:
        locale === "en"
          ? tenant.privacyPolicyURL
          : tenant.privacyPolicyFRURL || "",
      icon: (
        <MdOutlinePrivacyTip
          key="icon-privacy"
          size={16}
          color="var(--color-brand)"
          style={iconStyle}
        />
      ),
    },
    {
      id: "logout",
      label: t("logout"),
      onClick: handleLogout,
      icon: (
        <IoIosLogOut size={16} color="var(--color-brand)" style={iconStyle} />
      ),
    },
  ];

  return (
    <Flex
      className={styles.header}
      direction="row"
      align="center"
      justify="between"
    >
      <UserSettingsModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        handleLogout={handleLogout}
      />
      <Flex align="center" gap={5}>
        <NavbarLogo />
        {!isMobile && (
          <Button
            as="a"
            // Polymorphic component, attribute will be passed to the underlying `as` element
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            href={portfolioHref}
            title={`${t("portfolio")}`}
            variant="primary"
            className={styles.home_btn}
            data-dd-action-name="my portfolio"
          >
            <BsBuilding
              size={15}
              style={{
                marginRight: 15,
              }}
            />
            {t("myPortfolio")}
          </Button>
        )}
      </Flex>
      <OverflowMenu
        options={applicantMenuItems}
        getOptionKey={(option) => option.id}
        renderOption={option}
        className={styles.applicant_menu_dropdown}
        button={MenuButton}
        menuPlacement="left"
      />
    </Flex>
  );
};

type ApplicantMenuItemsTypeCommon = {
  id: string;
  label: string;
  icon: React.ReactNode;
};

type ApplicantMenuItemLink = ApplicantMenuItemsTypeCommon & {
  link: string;
  onClick?: never;
};

type ApplicantMenuItemOnClick = ApplicantMenuItemsTypeCommon & {
  onClick: () => void;
  link?: never;
};

type ApplicantMenuItemsType = ApplicantMenuItemLink | ApplicantMenuItemOnClick;
